import { render, staticRenderFns } from "./CollapseType.vue?vue&type=template&id=01688a9d&"
import script from "./CollapseType.vue?vue&type=script&lang=js&"
export * from "./CollapseType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports